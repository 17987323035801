<template>
  <main class="page__content">
    <ContentHeader
      v-bind:title="content_header.title"
      v-bind:subTitle="content_header.subTitle"
    />
  </main>
</template>

<script>
export default {
  name: "Authentication",

  data: function () {
    return {
      content_header: {
        title: "Federated Authentication",
        subTitle:
          "Manage the products offerings that you have available to your customers by creating and editing loan.",
      },
    };
  },
};
</script>
